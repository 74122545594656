<template>
  <div class="d-block fill-height neutral">
    <logo-group
      class="px-6 py-6"
      :logomark="true"
      :logotype="true"
    />
    <v-container
      fluid
    >
      <v-responsive :class="{'py-0': $vuetify.breakpoint.smAndDown, 'py-5': $vuetify.breakpoint.mdAndUp}">
        <v-container>
          <v-row
            class="align-center justify-center"
          >
            <v-col
              order="2"
              order-sm="1"
              cols="12"
              md="8"
            >
              <h1>{{ $t('Pages.Cookies.policy') }}</h1>
              <h3>{{ $t('Pages.Cookies.policy_since') }}</h3>
              <br>
              <h4>{{ $t('Pages.Cookies.cookies_intro') }}:</h4>
              <div
                v-for="item in cookieInfo"
                :key="item.title"
                class="mt-4"
              >
                <h3>{{ $t(item.title) }}</h3>
                <p>
                  <ul style="padding-left: 40px; list-style-type: disc;">
                    <li
                      v-for="text in item.list"
                      :key="text"
                      style="text-align: justify;"
                    >
                      {{ $t(text) }}
                    </li>
                  </ul>
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-responsive>
    </v-container>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import LogoGroup from '@/common/LogoGroup.vue'

export default {
  name: 'Cookies',
  components: {
    LogoGroup
  },
  metaInfo () {
    return {
      title: this.brandName
    }
  },
  data () {
    return {
      cookieInfo: [
        {
          title: 'Pages.Cookies.payment',
          list: [
            'Pages.Cookies.payment_authentication',
            'Pages.Cookies.payment_fraud_prevention',
            'Pages.Cookies.payment_site_features',
            'Pages.Cookies.payment_improve_services',
            'Pages.Cookies.payment_advertising'
          ]
        },
        {
          title: 'Pages.Cookies.google_material',
          list: [
            'Pages.Cookies.google_material_analytics',
            'Pages.Cookies.google_material_optimizer'
          ]
        }
      ]
    }
  },
  computed: {
    ...mapState(['brandName'])
  }
}
</script>
